import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Container } from "@material-ui/core";

class Header extends Component {
  state = {};
  render() {
    return (
      <AppBar position="static" className="app-bar">
        <Container fixed>
          <Toolbar>
            <Grid container spacing={3} alignItems="flex-end">
              <Grid item>
                <span className="wordmark">Match</span>
              </Grid>
              <Grid item>
                <Typography className="powered-by" variant="caption">
                  Powered by Myplanet
                </Typography>
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
    );
  }
}

export default Header;
