import React, { Component } from "react";
import { Grid, Button, Hidden } from "@material-ui/core";
import { CallToAction, Tagline } from "./flexText";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Panel from "./panel";
import { styled } from "@material-ui/core/styles";

const MyButton = styled(Button)({
  borderColor: "white",
  "& .MuiButton-label": {
    position: "relative",
    top: "2px",
  },
});

class PanelEnd extends Component {
  state = {};
  render() {
    return (
      <Panel backgroundColor="finished" color="white" alignItems="center">
        <Grid container spacing={4} alignItems="center">
          <Hidden>
            <Grid item xs={12} md={7}>
              <CheckCircleOutlineIcon className="icon-check" />
              <CallToAction>Request submitted</CallToAction>
              <Tagline color="white">
                Our partner team will be in touch shortly.{" "}
              </Tagline>
              <MyButton
                variant="outlined"
                onClick={this.props.onPanelSwitchRequest}
              >
                <span>Return to Homepage</span>
              </MyButton>
            </Grid>
          </Hidden>
          <Hidden lgDown>
            <Grid item></Grid>
          </Hidden>
        </Grid>{" "}
      </Panel>
    );
  }
}

export default PanelEnd;
