import React, { Component } from "react";
import { Button, TextField, Grid, CircularProgress } from "@material-ui/core";
import Panel from "./panel";
import { styled } from "@material-ui/core/styles";

const MyButton = styled(Button)({
  borderColor: "white",
  "& .MuiButton-label": {
    position: "relative",
    top: "2px",
  },
});

const MyTextField = styled(TextField)(({ theme, props }) => ({
  "& input": {
    position: "relative",
    top: "-33px",
    marginBottom: "-33px",
    fontWeight: "bold",
    fontSize: "200%",
    color: theme.palette.primary.main,
  },
  "& label": {
    position: "relative",
    fontWeight: "bold",
    fontSize: "220%",
    opacity: "0.7",
  },
  "& .MuiButton-Label": {
    position: "relative",
    top: "2px",
  },
  "& .MuiInputLabel-shrink": {
    transform: "translate(0, 1.5px) scale(0.5)",
  },
}));

class PanelForm extends Component {
  state = { finished: false, progress: 0, submitting: false };

  constructor(props) {
    super(props);
    this.fieldCount = 3;
    this.mTextFields = [];
    for (var i = 0; i < this.fieldCount; i++) {
      this.mTextFields.push(React.createRef());
    }
  }

  handleOnChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
    let progress = 0;
    for (const mRef of this.mTextFields) {
      if (mRef.current.children[1].children["0"].value !== "") {
        progress += 1 / this.fieldCount;
      }
    }
    this.setState({ progress: progress * 100 });
    // this.props.onProgressChange(progress * 100);
    if (progress >= 1.0) {
      this.setState({ finished: true });
    } else {
      this.setState({ finished: false });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ submitting: true });

    // Simple POST request with a JSON body using fetch
    const requestOptions = {
      mode: "no-cors",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: this.props.userName,
        email: this.props.userEmail,
        location: this.state.location,
        industry: this.state.industry,
        role: this.state.role,
        // interest: this.state.interest,
      }),
    };
    fetch(
      "https://us-central1-myplanet-match.cloudfunctions.net/mail",
      requestOptions
    )
      .then((response) => {
        this.props.onPanelSwitchRequest();
        this.setState({ submitting: false });
      })
      .then((data) => {});
  };

  render() {
    return (
      <Panel alignItems="center">
        <Grid container alignItems="center">
          {/* Add two grid items to center the main content */}
          <Grid item xs={12}>
            <form onSubmit={this.handleSubmit} noValidate autoComplete="off">
              <span className="panel-form-label">
                I’m looking to connect with
              </span>
              <p></p>
              <span className="panel-form-label">people in</span>
              <MyTextField
                type="text"
                ref={this.mTextFields[0]}
                onChange={this.handleOnChange}
                id="location"
                label="location"
              />
              <p></p>
              <span className="panel-form-label">who are working in</span>
              <MyTextField
                type="text"
                ref={this.mTextFields[1]}
                onChange={this.handleOnChange}
                id="industry"
                label="industry or company"
              />
              <p></p>
              <span className="panel-form-label">as</span>
              <MyTextField
                type="text"
                ref={this.mTextFields[2]}
                onChange={this.handleOnChange}
                id="role"
                label="role/title"
              />
              <p></p>
              {/* <span className="panel-form-label">and interested in </span>
              <MyTextField
                type="text"
                ref={this.mTextFields[3]}
                onChange={this.handleOnChange}
                id="interest"
                label="subject"
                color="primary"
              />
              <br /> */}
              <MyButton
                type="submit"
                disabled={!this.state.finished || this.state.submitting}
                className="btn-form"
                variant="contained"
                color="secondary"
              >
                {this.state.submitting ? <CircularProgress /> : "Find Matches"}
              </MyButton>
            </form>
          </Grid>
        </Grid>
      </Panel>
    );
  }
}

export default PanelForm;
