import React, { Component } from "react";
import { Container, Grid, Box } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const MyBox = styled(({ backgroundColor, color, ...other }) => (
  <Box {...other} />
))({
  color: (props) => props.color,
  backgroundColor: (props) =>
    props.backgroundColor === "finished" ? "#078758" : "",
});

const MyContainer = styled(Container)({
  backgroundColor: (props) => props.backgroundColor,
});

const MyGrid = styled(Grid)(({ theme }) => ({
  minHeight: "88vh",
  minWidth: "420px",
  [theme.breakpoints.down("sm")]: {
    paddingBottom: "40px",
  },
}));

class Panel extends Component {
  state = {};
  render() {
    return (
      <MyBox
        backgroundColor={this.props.backgroundColor}
        color={this.props.color}
      >
        <MyContainer fixed>
          <MyGrid container justify="center" alignItems={this.props.alignItems}>
            <Grid item xs={1}></Grid>
            <Grid item xs={11}>
              {this.props.children}
            </Grid>
          </MyGrid>
        </MyContainer>
      </MyBox>
    );
  }
}

export default Panel;
