import React from "react";
import Header from "./components/header";
// import { Collapse, LinearProgress } from "@material-ui/core";
import { Collapse } from "@material-ui/core";
import PanelHero from "./components/panelHero";
import PanelForm from "./components/panelForm";
import PanelEnd from "./components/panelEnd";

function App() {
  const [step, setStep] = React.useState(0);
  const [name, setName] = React.useState(0);
  const [email, setEmail] = React.useState(0);
  // const [progress, setProgress] = React.useState(0);

  const handlePanelSwitchRequest = () => {
    if (step === 2) {
      setStep(0);
    } else {
      setStep(step + 1);
    }
  };

  const handleUserLogged = (user) => {
    // console.log(user);
    setName(
      user.profile.localizedFirstName + " " + user.profile.localizedLastName
    );
    setEmail(user.email);
  };

  return (
    <React.Fragment>
      <Header />
      {/* <LinearProgress variant="determinate" value={progress} color="primary" /> */}
      <Collapse in={step === 0} timeout={800}>
        <PanelHero
          onPanelSwitchRequest={handlePanelSwitchRequest}
          onUserLogged={handleUserLogged}
        />
      </Collapse>
      <Collapse in={step === 1} timeout={1000}>
        <PanelForm
          onPanelSwitchRequest={handlePanelSwitchRequest}
          // onProgressChange={setProgress}
          userName={name}
          userEmail={email}
        />
      </Collapse>
      <Collapse in={step === 2} timeout={800}>
        <PanelEnd onPanelSwitchRequest={handlePanelSwitchRequest} />
      </Collapse>
    </React.Fragment>
  );
}

export default App;
