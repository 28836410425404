import React, { Component } from "react";
import { Grid, Hidden } from "@material-ui/core";
import { CallToAction, Tagline } from "./flexText";
import LinkedInButton from "./linkedInButton";
import Panel from "./panel";
import { styled } from "@material-ui/core/styles";

const TopGrid = styled(Grid)(({ theme }) => ({
  marginTop: "0vh",
  [theme.breakpoints.up("lg")]: {
    backgroundImage: 'url("./images/hero.png")',
    backgroundPosition: "right top",
    backgroundRepeat: "no-repeat",
    backgroundSize: "670px auto",
    minHeight: "720px",
  },
  [theme.breakpoints.down("md")]: {
    backgroundImage: 'url("./images/hero-mobile.png")',
    backgroundPosition: "right top",
    backgroundRepeat: "no-repeat",
    backgroundSize: "400px auto",
    minHeight: "400px",
  },
  [theme.breakpoints.down("sm")]: {
    minHeight: "0vh",
    height: "auto",
    backgroundImage: "none",
  },
}));

const MainGrid = styled(Grid)(({ theme }) => ({
  // backgroundImage:
  //   "linear-gradient(to right, rgba(250, 250, 250, 1), rgba(250, 250, 250, 1), rgba(250, 250, 250, 1), rgba(250, 250, 250, 1), rgba(250, 250, 250, 1), rgba(250, 250, 250, 0.15))",
  paddingBottom: "10%",
  paddingTop: "70px",
  [theme.breakpoints.down("md")]: {
    paddingTop: "50px",
  },
  [theme.breakpoints.down("sm")]: {
    height: "auto",
    backgroundImage: "none",
  },
}));

const BottomGrid = styled(Grid)(({ theme }) => ({
  paddingTop: "20px",
  width: "380px",
}));

class Hero extends Component {
  state = {};

  authorize = () => {
    var oauthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=7894d42y6bc29o&scope=r_liteprofile,r_emailaddress&state=123456&redirect_uri=https://auth-dot-myplanet-match.ue.r.appspot.com/linkedin/callback`;
    var width = 450,
      height = 730,
      left = window.screen.width / 2 - width / 2,
      top = window.screen.height / 2 - height / 2;

    window.open(
      oauthUrl,
      "Linkedin",
      "menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=" +
        width +
        ", height=" +
        height +
        ", top=" +
        top +
        ", left=" +
        left
    );
  };

  componentDidMount() {
    window.addEventListener("message", this.handleMessage);
  }

  handleMessage = (msg) => {
    if (msg.data.type === "user") {
      this.props.onUserLogged(msg.data.user);
      this.props.onPanelSwitchRequest();
    }
  };

  render() {
    return (
      <Panel alignItems="center">
        <Hidden>
          <TopGrid container>
            <Hidden>
              <MainGrid container>
                <Grid item xs={12} md={7}>
                  <Grid container>
                    <Grid item xs={11}>
                      <CallToAction>
                        Find curated leads <br /> in our network
                      </CallToAction>
                      <Tagline>
                        Grow your funnel with warm introductions from <br />
                        your partners at Myplanet
                      </Tagline>
                      <LinkedInButton
                        onClick={this.authorize}
                        disableElevation
                      ></LinkedInButton>
                    </Grid>
                  </Grid>
                </Grid>
              </MainGrid>
            </Hidden>
            <Hidden lgDown>
              <Grid item></Grid>
            </Hidden>
          </TopGrid>
        </Hidden>
        <Hidden mdUp>
          <BottomGrid container>
            <img alt="Hero" width="100%" src="./images/hero-mobile.png" />
          </BottomGrid>
        </Hidden>
      </Panel>
    );
  }
}

export default Hero;
