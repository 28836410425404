import React, { Component } from "react";

class FlexTextBox extends Component {
  state = { fontSize: "2" };

  constructor(props) {
    super(props);
    this.mFlexTextBox = React.createRef();
    this.mFlexTextBoxInner = React.createRef();
  }

  updateDimensions() {
    var relFontsize =
      0.8 *
      (this.mFlexTextBox.current.offsetWidth /
        this.mFlexTextBoxInner.current.offsetWidth) *
      this.state.fontSize;
    this.setState({ fontSize: relFontsize });
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  render() {
    const styles = {
      containerStyle: {
        fontSize: this.state.fontSize + "px",
      },
    };

    const { containerStyle } = styles;

    return (
      <div
        ref={this.mFlexTextBox}
        style={containerStyle}
        className={"flex-text-box"}
      >
        <div
          className={this.props.className + " flex-text-box-inner"}
          ref={this.mFlexTextBoxInner}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default FlexTextBox;
