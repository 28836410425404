import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import SofiaProWoff2 from "./fonts/SofiaProRegular.woff2";
import { ThemeProvider, CssBaseline, createMuiTheme } from "@material-ui/core";

const sofiapro = {
  fontFamily: "Sofia Pro",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('SofiaPro'),
    local('SofiaProRegular'),
    url(${SofiaProWoff2}) format('woff2')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#4fadae",
      main: "#0d7d7f",
      dark: "#005053",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#545275",
      main: "#2a2a49",
      dark: "#020022",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: [
      "Sofia Pro",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [sofiapro],
      },
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <App />
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
