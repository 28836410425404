import React, { Component } from "react";
import { styled } from "@material-ui/core/styles";
import { Linkedin } from "mdi-material-ui";
import { Button } from "@material-ui/core";

const MyButton = styled(Button)({
  backgroundColor: "#0177b5",
  fontWeight: "bold",
  letterSpacing: "1px",
  fontSize: "100%",
  "&:hover": {
    backgroundColor: "#1187c5",
  },
  "& .MuiButton-label": {
    position: "relative",
    top: "2px",
    "& .MuiButton-startIcon": {
      position: "relative",
      top: "-2px",
    },
  },
});

class LinkedInButton extends Component {
  state = {};

  render() {
    return (
      <MyButton
        startIcon={<Linkedin />}
        variant="contained"
        onClick={this.props.onClick}
      >
        Sign in with LinkedIn
      </MyButton>
    );
  }
}

export default LinkedInButton;
