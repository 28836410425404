import React, { Component } from "react";
import FlexTextBox from "./flexTextBox";
import { styled } from "@material-ui/core/styles";

const MyFlexTextBox = styled(FlexTextBox)(({ theme }) => ({
  lineHeight: "1.8em",
}));

class Tagline extends Component {
  state = {};
  render() {
    return (
      <MyFlexTextBox color={this.props.color}>
        {this.props.children}
      </MyFlexTextBox>
    );
  }
}

export default Tagline;
