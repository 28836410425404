import React, { Component } from "react";
import FlexTextBox from "./flexTextBox";
import { styled } from "@material-ui/core/styles";

const MyFlexTextBox = styled(FlexTextBox)(({ theme }) => ({
  // fontFamily: "Sofia Pro",
  fontWeight: "bold",
  marginBottom: "0.3em",
  lineHeight: "1.4em",
  // color: #22272b;
}));

class CallToAction extends Component {
  state = {};
  render() {
    return <MyFlexTextBox>{this.props.children}</MyFlexTextBox>;
  }
}

export default CallToAction;
